import { FunctionComponent, memo, useCallback } from "react";
import styles from "./Hero.module.css";

const Hero: FunctionComponent = memo(() => {
  const onButtonCTAClick = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  return (
    <section className={styles.hero}>
      <div className={styles.heroandbutton}>
        <div className={styles.hero1}>
          <h1 className={styles.publishYourBrandContainer}>
            <p className={styles.publishYourBrand}>Publish Your Brand</p>
            <p className={styles.inTheNews}> In The News</p>
          </h1>
        </div>
        <button className={styles.buttoncta} onClick={onButtonCTAClick}>
          <b className={styles.getStarted}>Get Started</b>
        </button>
      </div>
      <div className={styles.mediapartnersh}>
        <img
          className={styles.mediapartnershimageIcon}
          loading="lazy"
          alt=""
          src="/mediapartnershimage@2x.png"
        />
      </div>
    </section>
  );
});

export default Hero;
