import { FunctionComponent, memo } from "react";
import styles from "./Services.module.css";

const Services: FunctionComponent = memo(() => {
  return (
    <section className={styles.services}>
      <div className={styles.servicescontainer}>
        <div className={styles.servicesdescription}>
          <h1 className={styles.services1}>Services</h1>
          <h1 className={styles.weProvideIntegrated}>
            Integrated PR solutions tailored to meet your unique needs.
          </h1>
        </div>
        <div className={styles.cardservices}>
          <div className={styles.cardservices1}>
            <h1 className={styles.mediaPublication}>{`Media Publication `}</h1>
            <i
              className={styles.mediaRelationsPress}
            >{`Media Relations, Press Release, News Releases, Advertorial, Media Exposure, Media Pitching, Media Buying, Media Training, Media Gathering, Media Monitoring, Newsjacking, Op-eds, Publicity, PR Ethics, Reputation Management, Crisis Management, Press Conference, ATL. `}</i>
            <div
              className={styles.enhanceYourBrands}
            >{`Enhance your brand's growth through strategic media publications. Our comprehensive services guarantee the spotlight your brand deserves, transforming your story and narrative into compelling news and cultivating a positive reputation. `}</div>
          </div>
          <div className={styles.cardservices2}>
            <h1 className={styles.marketResearch}>{`Market Research `}</h1>
            <i
              className={styles.dataAnalytics}
            >{`Data Analytics & Metrics, Demographic & Psychographic Analysis, Market Insights, Industry & Sentiment Analysis, Trend Reports, Market Segmentation & Forecasting, Consumer Behavior, Brand Perception, Pricing Strategy, Statistical Modeling.  `}</i>
            <div
              className={styles.cultivateACompetitive}
            >{`Cultivate a competitive edge with our Tailored Artificial Intelligence Market Research Tools leveraging advanced LLMs such as OpenAI's ChatGPT, Google’s Gemini, Anthropic's Claude APIs and several relevant technologies. Benefit from data-driven insights, industry analysis, and trend reports to stay ahead. We provide the key to understanding your market for precise strategies. `}</div>
          </div>
          <div className={styles.cardservices3}>
            <h1 className={styles.branding}>{`Branding `}</h1>
            <i
              className={styles.brandIdentityBrand}
            >{`Brand Identity, Brand Image & Trust Management, Storytelling, Narrative Impact, Creative Idea Generation, Content Creation, Digital Presence, Social Media Management, Media Kits & Press Materials & Development, Remarketing, Website & App Management, SEO Strategy, E-Commerce Enhancement, Lead Generation, Product Reviews, Newsletter Production, Advertisement, BTL.  `}</i>
            <div
              className={styles.amplifyYourBrands}
            >{`Amplify your brand's identity and presence with our branding expertise. Shine through brand management, storytelling, creative content, and increased visibility, ensuring success and growth.  `}</div>
          </div>
          <div className={styles.cardservices4}>
            <h1 className={styles.partnerships}>{`Partnerships `}</h1>
            <i
              className={styles.strategicPartnershipsBrand}
            >{`Strategic Partnerships, Brand Collaborations, Event Promotion & Sponsorships, Brand Ambassadors, Celebrity Endorsements, KOL & Influencer Partnerships, Blog & Vlog, Podcast, Film & Series Placements, TTL. `}</i>
            <div
              className={styles.forgePowerfulPartnerships}
            >{`Forge powerful partnerships to enhance your brand's influence. Thrive through strategic collaborations, promotions, and endorsements, connecting with the right collaborators for authentic audience engagements.  `}</div>
          </div>
          <div className={styles.cardservices5}>
            <h1 className={styles.publicAffairs}>{`Public Affairs `}</h1>
            <i
              className={styles.governmentRelationsStakehol}
            >{`Government Relations, Stakeholder Engagements, Advocacy, Lobbying, Community Relations, Industry Awards Submissions, Corporate Social Responsibility, Sustainability, Green Initiatives, Philanthropic Initiatives. `}</i>
            <div
              className={styles.navigatePublicAffairs}
            >{`Navigate public affairs with confidence. Benefit from our expertise in government relations, advocacy, and sustainability, reinforcing your brand's reputation and influence. `}</div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Services;
