import { FunctionComponent, memo } from "react";
import styles from "./Results.module.css";

const Results: FunctionComponent = memo(() => {
  return (
    <section className={styles.results}>
      <div className={styles.resultscontainer}>
        <div className={styles.resultsdescription}>
          <h1 className={styles.results1}>Results</h1>
          <h1
            className={styles.weDeliverMeasurable}
          >{`Delivered measurable results. `}</h1>
        </div>
        <div className={styles.cardresults}>
          <div className={styles.cardresults1}>
            <div className={styles.div}>1,369+</div>
            <div className={styles.brandsServed}>{`Brands Served. `}</div>
          </div>
          <div className={styles.cardresults2}>
            <div className={styles.div1}>9,063+</div>
            <div className={styles.articlesPublished}>Articles Published.</div>
          </div>
          <div className={styles.cardresults3}>
            <div className={styles.div2}>150%</div>
            <div className={styles.moreLeads}>{`More Leads. `}</div>
          </div>
          <div className={styles.cardresults4}>
            <div className={styles.div3}>34%</div>
            <div className={styles.moreSales}>{`More Sales. `}</div>
          </div>
          <div className={styles.cardresults5}>
            <div className={styles.x}>3X</div>
            <div
              className={styles.moreWebsiteTraffic}
            >{`More Website Traffic. `}</div>
          </div>
          <div className={styles.cardresults6}>
            <div className={styles.x1}>4X</div>
            <div className={styles.moreEngagements}>{`More Engagements. `}</div>
          </div>
          <div className={styles.cardresults7}>
            <div className={styles.x2}>5X</div>
            <div className={styles.moreExposure}>{`More Exposure. `}</div>
          </div>
          <div className={styles.cardresults8}>
            <div className={styles.div4}>100%</div>
            <div
              className={styles.verifiedOnSocial}
            >{`Verified on Social Media. `}</div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Results;
