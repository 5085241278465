import { FunctionComponent, memo, useCallback } from "react";
import styles from "./Footer.module.css";

const Footer: FunctionComponent = memo(() => {
  const onLogoContainerClick = useCallback(() => {
    window.open("https://cadiauv.com");
  }, []);

  const onButtonCTA1Click = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  const onCadiauvLogomarkIconClick = useCallback(() => {
    window.open("https://cadiauv.com");
  }, []);

  const onIconEmailClick = useCallback(() => {
    window.location.href = "mailto:hi@cadiauv.com";
  }, []);

  const onIconWhatsAppClick = useCallback(() => {
    window.open("https://wa.me/6289630285874");
  }, []);

  const onIconCrunchbaseClick = useCallback(() => {
    window.open("https://crunchbase.com/organization/cadiauv");
  }, []);

  const onIconInstagramClick = useCallback(() => {
    window.open("https://instagram.com/interpublicipg");
  }, []);

  const onIconLinkedInClick = useCallback(() => {
    window.open("http://linkedin.com/company/ipg");
  }, []);

  return (
    <section className={styles.footer}>
      <div className={styles.footercontainer}>
        <div className={styles.logo} onClick={onLogoContainerClick}>
          <img
            className={styles.cadiauvLogoIcon}
            loading="lazy"
            alt=""
            src="/cadiauvlogo-1.svg"
          />
        </div>
        <div className={styles.citation}>
          <div className={styles.citation1}>
            <h1 className={styles.customersOnlyLoyal}>
              "Customers Only Loyal to Reputable Brands."
            </h1>
            <div className={styles.harvardBusinessReview}>
              Harvard Business Review by Robert G. Eccles, Scott C. Newquist,
              and Roland Schatz.
            </div>
          </div>
          <div className={styles.citation2}>
            <h1 className={styles.newsPublicityHasContainer}>
              <p
                className={styles.newsPublicityHas}
              >{`"News Publicity Has A Significant Impact `}</p>
              <p className={styles.onCompanysReputation}>
                on Company's Reputation."
              </p>
            </h1>
            <div className={styles.mitSloanManagement}>
              MIT Sloan Management Review by Grahame Dowling and Warren Weeks.
            </div>
          </div>
          <div className={styles.citation3}>
            <h1 className={styles.mediaPublicationsIncrease}>
              "Media Publications Increase Sales Exponentially."
            </h1>
            <div className={styles.stanfordGraduateSchool}>
              Stanford Graduate School of Business by Jonah Berger, Alan T.
              Sorensen, and Scott J. Rasmussen.
            </div>
          </div>
        </div>
        <button className={styles.buttoncta} onClick={onButtonCTA1Click}>
          <b className={styles.getStarted}>Get Started</b>
        </button>
        <div className={styles.links}>
          <img
            className={styles.cadiauvLogomarkIcon}
            loading="lazy"
            alt=""
            src="/cadiauvlogomark.svg"
            onClick={onCadiauvLogomarkIconClick}
          />
          <div className={styles.location}>
            <img
              className={styles.iconLocation}
              loading="lazy"
              alt=""
              src="/icon--location.svg"
            />
            <i className={styles.jlYudistiraNo}>
              Jl. Yudistira No. 3A, Seminyak, Kuta, Bali, Indonesia 80361
            </i>
          </div>
          <div className={styles.outsidelinks}>
            <img
              className={styles.iconEmail}
              loading="lazy"
              alt=""
              src="/icon--email.svg"
              onClick={onIconEmailClick}
            />
            <img
              className={styles.iconWhatsapp}
              loading="lazy"
              alt=""
              src="/icon--whatsapp@2x.png"
              onClick={onIconWhatsAppClick}
            />
            <img
              className={styles.iconCrunchbase}
              loading="lazy"
              alt=""
              src="/icon--crunchbase.svg"
              onClick={onIconCrunchbaseClick}
            />
            <img
              className={styles.iconInstagram}
              loading="lazy"
              alt=""
              src="/icon--instagram.svg"
              onClick={onIconInstagramClick}
            />
            <img
              className={styles.iconLinkedin}
              loading="lazy"
              alt=""
              src="/icon--linkedin.svg"
              onClick={onIconLinkedInClick}
            />
          </div>
          <i className={styles.partOfIpg}>Part of IPG Networks</i>
          <div className={styles.cadiauvAllRightsContainer}>
            {`© `}
            <a
              className={styles.cadiauv}
              href="https://cadiauv.com"
              target="_blank"
            >
              <span className={styles.cadiauv1}>Cadiauv</span>
            </a>
            . All Rights Reserved.
          </div>
        </div>
      </div>
    </section>
  );
});

export default Footer;
