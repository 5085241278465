import { FunctionComponent, memo } from "react";
import styles from "./ClientRosters.module.css";

const ClientRosters: FunctionComponent = memo(() => {
  return (
    <section className={styles.clientrosters}>
      <div className={styles.clientrosterscontainer}>
        <div className={styles.clientrostersdescription}>
          <h1 className={styles.clientRosters}>Client Rosters</h1>
          <h1 className={styles.wevePartneredWith}>
            Collaborated with 1.369+ Brands.
          </h1>
        </div>
      </div>
      <div className={styles.clientrostersh}>
        <img
          className={styles.clientrostershimageIcon}
          loading="lazy"
          alt=""
          src="/clientrostershimage@2x.png"
        />
      </div>
    </section>
  );
});

export default ClientRosters;
