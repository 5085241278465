import { FunctionComponent, memo, useCallback } from "react";
import styles from "./NavigationBar.module.css";

const NavigationBar: FunctionComponent = memo(() => {
  const onLogoContainerClick = useCallback(() => {
    window.open("https://cadiauv.com");
  }, []);

  const onButtonCTAClick = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  return (
    <header className={styles.navigationbar}>
      <div className={styles.navigationbarcontainer}>
        <div className={styles.logo} onClick={onLogoContainerClick}>
          <img
            className={styles.cadiauvLogoIcon}
            loading="lazy"
            alt=""
            src="/cadiauvlogo.svg"
          />
        </div>
        <button className={styles.buttoncta} onClick={onButtonCTAClick}>
          <b className={styles.getStarted}>Get Started</b>
        </button>
      </div>
    </header>
  );
});

export default NavigationBar;
