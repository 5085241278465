import { FunctionComponent, memo } from "react";
import styles from "./FAQ.module.css";

const FAQ: FunctionComponent = memo(() => {
  return (
    <section className={styles.faq}>
      <div className={styles.faqcontainer}>
        <div className={styles.faqdescription}>
          <h1 className={styles.faq1}>FAQ</h1>
          <h1
            className={styles.frequentlyAskedQuestions}
          >{`Frequently Asked Questions. `}</h1>
        </div>
        <div className={styles.cardfaq}>
          <div className={styles.cardfaq1}>
            <div
              className={styles.whatIsPublic}
            >{`What is Public Relations Agency? `}</div>
            <div className={styles.publicRelationsPrContainer}>
              <p className={styles.publicRelationsPr}>
                Public Relations (PR) is the strategic management of
                communication between an organization (enterprise/brand/startup)
                and its audience to build and maintain a positive public image.
              </p>
              <p className={styles.p}></p>
              <p
                className={styles.aPublicRelations}
              >{`A Public Relations (PR) Agency is a professional service that specializes in crafting and implementing communication strategies to enhance the reputation and perception of organizations (enterprises/brands/startups).  `}</p>
            </div>
          </div>
          <div className={styles.cardfaq2}>
            <div
              className={styles.whatIsA}
            >{`What is a Media Publication? `}</div>
            <div
              className={styles.mediaPublicationIs}
            >{`Media publication is a platform, such as a newspaper, magazine, website, or broadcast channel, where content is created and disseminated to the public. It includes news articles, press release, features, and advertisements. `}</div>
          </div>
          <div className={styles.cardfaq3}>
            <div
              className={styles.whyPublicRelations}
            >{`Why Public Relations is needed? `}</div>
            <div className={styles.organizationsEnterprisesbra}>
              Organizations (enterprises/brands/startups) need Public Relations
              to increase visibility, credibility, and trust. It helps in
              reaching a wider audience, getting positive media coverage, and
              managing reputation in the competitive business landscape.
            </div>
          </div>
          <div className={styles.cardfaq4}>
            <div className={styles.whyCadiauv}>{`Why Cadiauv? `}</div>
            <div className={styles.atCadiauvOurContainer}>
              <p className={styles.atCadiauvOur}>
                At Cadiauv, our expertise in crafting compelling PR strategies
                tailored to your company's unique needs. We have a proven track
                record of delivering results, building strong media
                relationships, and ensuring your company's message stands out in
                a crowded market.
              </p>
              <p className={styles.p1}></p>
              <p className={styles.atCadiauvWe}>
                At Cadiauv, we understand the power of media exposure. When your
                company gets featured in the media, it's a game-changer. Not
                only will your brand rank higher on Google search results, but
                it will also be indexed on Google News, Bing News, and Apple
                News. Your future customers often turn to media publications to
                discover brands they can trust. What's more, our track record
                speaks for itself – on average, just 1-3 months after
                publication, our clients experience a remarkable 3X increase in
                website traffic, 150% more leads, and a significant boost in
                sales by 34%.
              </p>
              <p className={styles.p2}></p>
              <p
                className={styles.atCadiauvWe1}
              >{`At Cadiauv, we offer you three key advantages that set us apart. First, our proven expertise, backed by over a decade of experience, allows us to expertly shape the narrative of your brand's story, ensuring it resonates with your prospective customers. Second, our extensive network of 300+ Indonesian and International Media Partners means your brand gains swift and comprehensive media coverage. And third, we believe in affordability as a core principle – our competitive pricing model means that brands of all sizes can access the benefits of mainstream media exposure. `}</p>
            </div>
          </div>
          <div className={styles.cardfaq5}>
            <div className={styles.howItWorks}>{`How it works? `}</div>
            <div className={styles.selectPleaseContactContainer}>
              <p className={styles.select}>1). Select</p>
              <p className={styles.pleaseContactUs}>
                Please contact us to select the media of your choice.
              </p>
              <p className={styles.p3}></p>
              <p className={styles.writing}>2). Writing</p>
              <p className={styles.yourArticleWill}>
                Your article will be written by our expert team. (Or you can
                send us your pre-written article draft.)
              </p>
              <p className={styles.p4}></p>
              <p className={styles.published}>3). Published</p>
              <p className={styles.youWillReceive}>
                You will receive a full report with a live published article
                link on the media of your choice within only 1-3 days.
              </p>
            </div>
          </div>
          <div className={styles.cardfaq6}>
            <div
              className={styles.whatTypeOf}
            >{`What type of entities do you work with? `}</div>
            <div
              className={styles.weExcelIn}
            >{`We excel in collaborating with diverse brands across a spectrum of industries, ranging from large enterprises to emerging brands and startups. `}</div>
          </div>
          <div className={styles.cardfaq7}>
            <div
              className={styles.howCanThis}
            >{`How can this benefit my entity? `}</div>
            <div
              className={styles.publicRelationsAnd}
            >{`Public Relations and Media Publication can enhance brand visibility, reputation, and credibility in any industry. We customize our strategies to align with your unique goals and tailor them to your brand’s messages. `}</div>
          </div>
          <div className={styles.cardfaq8}>
            <div
              className={styles.whatResultsCan}
            >{`What results can I expect from you? `}</div>
            <div
              className={styles.expectIncreasedMedia}
            >{`Expect increased media coverage, improved brand perception, and measurable growth in website traffic, leads, and sales. As the result of our clients, on average, just 1-3 months after publication, our clients experience a remarkable 3X increase in website traffic, 150% more leads, and a significant boost in sales by 34%. `}</div>
          </div>
          <div className={styles.cardfaq9}>
            <div
              className={styles.doYouOffer}
            >{`Do you offer other services? `}</div>
            <div className={styles.absolutelyApartFromContainer}>
              <p className={styles.absolutelyApartFrom}>
                Absolutely, apart from our Media Publication services, we offer
                a comprehensive range of solutions, including Market Research,
                Branding, Partnerships, and Public Affairs.
              </p>
              <p className={styles.p5}></p>
              <p
                className={styles.weInviteYou}
              >{`We invite you to reach out to us for an in-depth discussion of your PR Campaign goals. `}</p>
            </div>
          </div>
          <div className={styles.cardfaq10}>
            <div
              className={styles.additionalQuestions}
            >{`Additional questions? `}</div>
            <div className={styles.pleaseContactUsContainer}>
              {`Please `}
              <a
                className={styles.contactUs}
                href="https://tally.so/r/n0e1PQ"
                target="_blank"
              >
                <span className={styles.contactUs1}>contact us</span>
              </a>
              {`. Our team is here to provide you with the support and information you need. We look forward to hearing from you. `}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default FAQ;
