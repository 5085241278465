import { FunctionComponent, memo } from "react";
import styles from "./MediaPartners.module.css";

const MediaPartners: FunctionComponent = memo(() => {
  return (
    <section className={styles.mediapartners}>
      <div className={styles.mediapartnerscontainer}>
        <div className={styles.mediapartnersdescription}>
          <h1 className={styles.mediaPartners}>Media Partners</h1>
          <h1
            className={styles.wevePartneredWith}
          >{`Partnered with 300+ Indonesian & International Media. `}</h1>
        </div>
      </div>
      <div className={styles.mediapartnersh}>
        <img
          className={styles.mediapartnershimageIcon}
          loading="lazy"
          alt=""
          src="/mediapartnershimage-1@2x.png"
        />
      </div>
    </section>
  );
});

export default MediaPartners;
