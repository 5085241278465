import { FunctionComponent, memo } from "react";
import styles from "./About.module.css";

const About: FunctionComponent = memo(() => {
  return (
    <section className={styles.about}>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutdescription}>
          <h1 className={styles.about1}></h1>
          <div className={styles.weAreCadiauvContainer}>
            <p className={styles.weAreCadiauv}>{`We are Cadiauv. `}</p>
            <p
              className={styles.aPublicRelations}
            >{`A Public Relations Agency `}</p>
            <p
              className={styles.locatedInBali}
            >{`located in Bali, Indonesia, `}</p>
            <p
              className={styles.establishedOnOctober}
            >{`established on October 28, 2013. `}</p>
            <p className={styles.blankLine}>&nbsp;</p>
            <p className={styles.weHavePublished}>{`We have published `}</p>
            <p
              className={styles.brandsAnd9063}
            >{`1.369+ Brands and 9.063+ Publications `}</p>
            <p
              className={styles.inIndonesianAnd}
            >{`in Indonesian and International Media `}</p>
            <p className={styles.forTheLast}>{`for the last decade. `}</p>
            <p className={styles.blankLine1}>&nbsp;</p>
            <p className={styles.weAreYour}>{`We are your strategic `}</p>
            <p
              className={styles.communicationsPartner}
            >{`communications partner `}</p>
            <p
              className={styles.inElevatingYour}
            >{`in elevating your reputation, `}</p>
            <p
              className={styles.inspiringPeopleTo}
            >{`inspiring people to inspire you further. `}</p>
          </div>
        </div>
        <img
          className={styles.galleryIcon}
          loading="lazy"
          alt=""
          src="/gallery@2x.png"
        />
      </div>
    </section>
  );
});

export default About;
