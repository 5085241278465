import { FunctionComponent, memo } from "react";
import styles from "./Workflow.module.css";

const Workflow: FunctionComponent = memo(() => {
  return (
    <section className={styles.workflow}>
      <div className={styles.workflowcontainer}>
        <div className={styles.workflowdescription}>
          <h1 className={styles.workflow1}>Workflow</h1>
          <h1 className={styles.ourWorkflowIs}>
            A simple 3-step workflow.
          </h1>
        </div>
        <div className={styles.cardworkflow}>
          <div className={styles.cardworkflow1}>
            <h1 className={styles.select}>1. Select</h1>
            <div className={styles.pleaseContactUsContainer}>
              {`Please `}
              <a
                className={styles.contactUs}
                href="https://tally.so/r/n0e1PQ"
                target="_blank"
              >
                <span className={styles.contactUs1}>contact us</span>
              </a>
              {` to select the media of your choice. `}
            </div>
          </div>
          <div className={styles.cardworkflow2}>
            <h1 className={styles.writing}>2. Writing</h1>
            <div
              className={styles.yourArticleWill}
            >{`Your article will be written by our team. (Or you can send us your pre-written article draft.) `}</div>
          </div>
          <div className={styles.cardworkflow3}>
            <h1 className={styles.publish}>3. Publish</h1>
            <div
              className={styles.youWillReceive}
            >{`You will receive a full report with a live published article link on the media of your choice within only 1-3 days. `}</div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Workflow;
