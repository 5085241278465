import { FunctionComponent } from "react";
import NavigationBar from "../components/NavigationBar";
import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
import MediaPartners from "../components/MediaPartners";
import ClientRosters from "../components/ClientRosters";
import Workflow from "../components/Workflow";
import Pricing from "../components/Pricing";
import Results from "../components/Results";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import styles from "./Cadiauv.module.css";

const Cadiauv: FunctionComponent = () => {
  return (
    <div className={styles.cadiauv}>
      <NavigationBar />
      <Hero />
      <About />
      <Services />
      <MediaPartners />
      <ClientRosters />
      <Workflow />
      <Pricing />
      <Results />
      <FAQ />
      <Footer />
    </div>
  );
};

export default Cadiauv;
