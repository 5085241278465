import { FunctionComponent, memo, useCallback } from "react";
import styles from "./Pricing.module.css";

const Pricing: FunctionComponent = memo(() => {
  const onButtonContainerClick = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  const onButtonCTAClick = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  const onButtonContainer2Click = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  const onButtonCTA2Click = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  const onButtonContainer3Click = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  const onButtonCTA3Click = useCallback(() => {
    window.open("https://tally.so/r/n0e1PQ");
  }, []);

  return (
    <section className={styles.pricing}>
      <div className={styles.pricingcontainer}>
        <div className={styles.pricingDescription}>
          <h1 className={styles.pricing1}>Pricing</h1>
          <h1
            className={styles.ourPricingIs}
          >{`Affordable & straightforward pricing. `}</h1>
        </div>
        <div className={styles.cardpricing}>
          <div className={styles.cardpricing1}>
            <div className={styles.pricingnameanddiscount}>
              <div className={styles.ultra}>Ultra</div>
              <button className={styles.discount}>
                <div className={styles.discount1}>64% DISCOUNT</div>
              </button>
            </div>
            <div className={styles.price}>
              <b className={styles.rp1800000}>Rp. 1.800.000</b>
              <div className={styles.article}>/ Article</div>
            </div>
            <div className={styles.minimum10ArticlesContainer}>
              <ul className={styles.minimum10ArticlesSelectMed}>
                <li className={styles.minimum10Articles}>
                  Minimum 10 Articles
                </li>
                <li className={styles.selectMediaOf}>
                  Select Media of Your Choice for Free
                </li>
                <li className={styles.articleWritingFor}>
                  Article Writing for Free
                </li>
                <li className={styles.duration13}>Duration : 1-3 Days</li>
                <li>{`Indexed by Google, Bing, Apple News `}</li>
              </ul>
            </div>
            <div className={styles.button} onClick={onButtonContainerClick}>
              <button className={styles.buttoncta} onClick={onButtonCTAClick}>
                <b className={styles.getStarted}>Get Started</b>
              </button>
            </div>
          </div>
          <div className={styles.cardpricing2}>
            <div className={styles.pricingnameanddiscount1}>
              <div className={styles.max}>Max</div>
              <button className={styles.discount2}>
                <div className={styles.discount3}>48% DISCOUNT</div>
              </button>
            </div>
            <div className={styles.price1}>
              <b className={styles.rp2400000}>Rp. 2.400.000</b>
              <div className={styles.article1}>/ Article</div>
            </div>
            <div className={styles.minimum5ArticlesContainer}>
              <ul className={styles.minimum5ArticlesSelectMedi}>
                <li className={styles.minimum5Articles}>Minimum 5 Articles</li>
                <li className={styles.selectMediaOf1}>
                  Select Media of Your Choice for Free
                </li>
                <li className={styles.articleWritingFor1}>
                  Article Writing for Free
                </li>
                <li className={styles.duration131}>Duration : 1-3 Days</li>
                <li>{`Indexed by Google, Bing, Apple News `}</li>
              </ul>
            </div>
            <div className={styles.button1} onClick={onButtonContainer2Click}>
              <button className={styles.buttoncta1} onClick={onButtonCTA2Click}>
                <b className={styles.getStarted1}>Get Started</b>
              </button>
            </div>
          </div>
          <div className={styles.cardpricing3}>
            <div className={styles.pricingnameanddiscount2}>
              <div className={styles.max1}>Pro</div>
              <button className={styles.discount4}>
                <div className={styles.discount5}>44% DISCOUNT</div>
              </button>
            </div>
            <div className={styles.price2}>
              <b className={styles.rp2800000}>Rp. 2.800.000</b>
              <div className={styles.article2}>/ Article</div>
            </div>
            <div className={styles.minimum1ArticlesContainer}>
              <ul className={styles.minimum1ArticlesSelectMedi}>
                <li className={styles.minimum1Articles}>Minimum 1 Article</li>
                <li className={styles.selectMediaOf2}>
                  Select Media of Your Choice for Free
                </li>
                <li className={styles.articleWritingFor2}>
                  Article Writing for Free
                </li>
                <li className={styles.duration132}>Duration : 1-3 Days</li>
                <li>{`Indexed by Google, Bing, Apple News `}</li>
              </ul>
            </div>
            <div className={styles.button2} onClick={onButtonContainer3Click}>
              <button className={styles.buttoncta2} onClick={onButtonCTA3Click}>
                <b className={styles.getStarted2}>Get Started</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Pricing;
